/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background-color: #FFD700; 
  color: #000000; 
}

.input-accentYellow {
  position: relative;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #FFC107; 
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.input-accentYellow[type='checkbox'] {
  border-radius: 0.2em; 
}

.input-accentYellow[type='checkbox']:checked {
  background-color: #FFC107;
  border-color: #FFC107;
}

.input-accentYellow[type='checkbox']:checked::before {
  content: ''; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
  color: white; 
}

.dark .input-accentYellow[type='checkbox']:checked::before {
  color: #333; 
}

.input-accentYellow[type='radio'] {
  border-radius: 50%;
}

.input-accentYellow[type='radio']:checked {
  background-color: #FFC107;
  border-color: #FFC107;
}

.input-accentYellow[type='radio']:checked::before {
  content: ''; 
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.5em;
  height: 0.5em;
  background-color: white; 
  border-radius: 50%; 
  transform: translate(-50%, -50%);
}

.dark .input-accentYellow[type='radio']:checked::before {
  background-color: #333; 
}

.input-accentYellow:hover {
  background-color: rgba(255, 193, 7, 0.2); 
  border-color: #FFC107;
}

.input-accentYellow:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.animate-spin-sun {
  transition: transform 0.5s ease;
}

.animate-spin-sun:hover {
  transform: rotate(180deg);
}

.animate-spin-moon {
  transition: transform 0.5s ease;
}

.animate-spin-moon:hover {
  transform: rotate(-180deg);
}
.hover-underline {
  position: relative;
  padding-bottom: 2px;
}

.hover-underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #FFC107;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.hover-underline:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
body {
  @apply bg-gray-100 dark:bg-gray-900;
}
*{
  font-family: Montserrat, sans-serif;
}